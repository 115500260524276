// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-licenzionnaja-muzyka-js": () => import("./../../../src/pages/licenzionnaja-muzyka.js" /* webpackChunkName: "component---src-pages-licenzionnaja-muzyka-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-ostavit-zajavku-js": () => import("./../../../src/pages/ostavit-zajavku.js" /* webpackChunkName: "component---src-pages-ostavit-zajavku-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-muzyka-dlja-fitnes-centra-js": () => import("./../../../src/pages/solutions/muzyka-dlja-fitnes-centra.js" /* webpackChunkName: "component---src-pages-solutions-muzyka-dlja-fitnes-centra-js" */),
  "component---src-pages-solutions-muzyka-dlja-kafe-i-restoranov-js": () => import("./../../../src/pages/solutions/muzyka-dlja-kafe-i-restoranov.js" /* webpackChunkName: "component---src-pages-solutions-muzyka-dlja-kafe-i-restoranov-js" */),
  "component---src-pages-solutions-muzyka-dlja-magazinov-js": () => import("./../../../src/pages/solutions/muzyka-dlja-magazinov.js" /* webpackChunkName: "component---src-pages-solutions-muzyka-dlja-magazinov-js" */),
  "component---src-pages-usage-js": () => import("./../../../src/pages/usage.js" /* webpackChunkName: "component---src-pages-usage-js" */),
  "component---src-templates-blog-post-index-jsx": () => import("./../../../src/templates/BlogPost/index.jsx" /* webpackChunkName: "component---src-templates-blog-post-index-jsx" */)
}

